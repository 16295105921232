<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
      <span class="page-title-icon bg-gradient-primary text-white mr-2">
        <i class="mdi mdi-home"></i>
      </span> Dashboard </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
          </li>
        </ul>
      </nav>
    </div>
           <div class="row">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class=" mdi mdi-border-all  text-danger icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Viewed Plots</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_viewed_plots }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'viewed'}">
                <i class="mdi mdi-bookmark-outliine mr-1" aria-hidden="true"></i> View more information </router-link></p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class=" mdi mdi-share  text-warning icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Shared Plot</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_shared_plots }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'shared-history'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class=" mdi mdi-share text-success icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Shared Plans</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_shared_plans }}</h3>
                  </div>
                </div>
              </div>
                <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'shared-plan-history'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class=" mdi mdi-heart text-info icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Favourite Plots</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_fav_plots }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'plot-favourite'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class=" mdi mdi-heart text-success icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Favourite Plans</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_fav_plans }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'plan-favourite'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="  mdi mdi-account-multiple text-primary icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Total Users</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_users }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'customer-list'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="  mdi mdi-comment text-warning icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Feedbacks</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ stats.total_feedbacks }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <router-link :to="{name: 'feedbacks'}">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </router-link> </p>
            </div>
          </div>
        </div>
             <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
               <div class="card card-statistics">
                 <div class="card-body">
                   <div class="clearfix">
                     <div class="float-left">
                       <i class="  mdi mdi-comment text-warning icon-lg"></i>
                     </div>
                     <div class="float-right">
                       <p class="mb-0 text-right">Top Browser</p>
                       <div class="fluid-container">
                         <h3 class="font-weight-medium text-right mb-0">{{ browsers }}</h3>
                       </div>
                     </div>
                   </div>
                   <p class="text-muted mt-3 mb-0">
                     <a :href="browserUrl" target="_blank">
                       <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </a> </p>
                 </div>
               </div>
             </div>
             <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
               <div class="card card-statistics">
                 <div class="card-body">
                   <div class="clearfix">
                     <div class="float-left">
                       <i class="  mdi mdi-comment text-warning icon-lg"></i>
                     </div>
                     <div class="float-right">
                       <p class="mb-0 text-right">Top Session</p>
                       <div class="fluid-container">
                         <h3 class="font-weight-medium text-right mb-0">{{ session.count ? session.count["ga:sessions"] : 0 }}</h3>
                       </div>
                     </div>
                   </div>
                   <p class="text-muted mt-3 mb-0">
                     <a :href="session.ga_url ? session.ga_url : ''" target="_blank">
                       <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </a> </p>
                 </div>
               </div>
             </div>
             <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
               <div class="card card-statistics">
                 <div class="card-body">
                   <div class="clearfix">
                     <div class="float-left">
                       <i class="  mdi mdi-comment text-warning icon-lg"></i>
                     </div>
                     <div class="float-right">
                       <p class="mb-0 text-right">Page Views</p>
                       <div class="fluid-container">
                         <h3 class="font-weight-medium text-right mb-0">{{ pageViews.count ? pageViews.count["ga:pageviews"] : 0 }}</h3>
                       </div>
                     </div>
                   </div>
                   <p class="text-muted mt-3 mb-0">
                     <a :href="pageViews.ga_url ? pageViews.ga_url : ''" target="_blank">
                       <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </a> </p>
                 </div>
               </div>
             </div>
             <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
               <div class="card card-statistics">
                 <div class="card-body">
                   <div class="clearfix">
                     <div class="float-left">
                       <i class="  mdi mdi-account-multiple text-primary icon-lg"></i>
                     </div>
                     <div class="float-right">
                       <p class="mb-0 text-right">Active Users</p>
                       <div class="fluid-container">
                         <h3 class="font-weight-medium text-right mb-0">{{ analyticsUsers.count ? analyticsUsers.count['ga:users'] : 0 }}</h3>
                       </div>
                     </div>
                   </div>
                   <p class="text-muted mt-3 mb-0">
                     <a :href="analyticsUsers.ga_url ? analyticsUsers.ga_url : ''" target="_blank">
                       <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> View more information </a> </p>
                 </div>
               </div>
             </div>
     </div>
    
    <!--
    <div class="row">
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Weekly Sales <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">$ 15,0000</h2>
            <h6 class="card-text">Increased by 60%</h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Weekly Orders <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">45,6334</h2>
            <h6 class="card-text">Decreased by 10%</h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Visitors Online <i class="mdi mdi-diamond mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">95,5741</h2>
            <h6 class="card-text">Increased by 5%</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <h4 class="card-title float-left">Visit And Sales Statistics</h4>
              <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right float-right">
                <ul>
                  <li>
                    <span class="legend-dots bg-gradient-primary"></span>CHN
                  </li>
                  <li>
                      <span class="legend-dots bg-gradient-danger"></span>USA
                  </li>
                  <li>
                        <span class="legend-dots bg-gradient-info"></span>UK
                  </li>
                </ul>
              </div>
            </div>
              <visitAndSalesStatitics class='mt-5' :height='170'></visitAndSalesStatitics>
          </div>
        </div>
      </div>
      <div class="col-md-5 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Traffic Sources</h4>
            <trafficSourceChart :height='200'></trafficSourceChart>
            <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pt-4">
              <ul>
                <li>
                  <span class="legend-dots bg-gradient-info"></span>Search Engines
                  <span class="float-right">30%</span>
                </li>
                <li>
                  <span class="legend-dots bg-gradient-success"></span>Direct Click
                  <span class="float-right">30%</span>
                </li>
                <li>
                  <span class="legend-dots bg-gradient-danger"></span>Bookmarks Click
                  <span class="float-right">40%</span>
                  </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Tickets</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> Assignee </th>
                    <th> Subject </th>
                    <th> Status </th>
                    <th> Last Update </th>
                    <th> Tracking ID </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face1.jpg" class="mr-2" alt="image"> David Grey </td>
                    <td> Fund is not recieved </td>
                    <td>
                      <label class="badge badge-gradient-success">DONE</label>
                    </td>
                    <td> Dec 5, 2017 </td>
                    <td> WD-12345 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face2.jpg" class="mr-2" alt="image"> Stella Johnson </td>
                    <td> High loading time </td>
                    <td>
                      <label class="badge badge-gradient-warning">PROGRESS</label>
                    </td>
                    <td> Dec 12, 2017 </td>
                    <td> WD-12346 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face3.jpg" class="mr-2" alt="image"> Marina Michel </td>
                    <td> Website down for one week </td>
                    <td>
                      <label class="badge badge-gradient-info">ON HOLD</label>
                    </td>
                    <td> Dec 16, 2017 </td>
                    <td> WD-12347 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face4.jpg" class="mr-2" alt="image"> John Doe </td>
                    <td> Loosing control on server </td>
                    <td>
                      <label class="badge badge-gradient-danger">REJECTED</label>
                    </td>
                    <td> Dec 3, 2017 </td>
                    <td> WD-12348 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> --><!--
    <div class="row">
      <div class="col-lg-5 grid-margin stretch-card">
        <div class="card">
          <date-picker v-model= "time1" valueType= "format" inline></date-picker>
        </div>
      </div>

      <div class="col-lg-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Updates</h4>
            <div class="d-flex">
              <div class="d-flex align-items-center mr-4 text-muted font-weight-light">
                <i class="mdi mdi-account-outline icon-sm mr-2"></i>
                <span>jack Menqu</span>
              </div>
              <div class="d-flex align-items-center text-muted font-weight-light">
                <i class="mdi mdi-clock icon-sm mr-2"></i>
                <span>October 3rd, 2018</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pr-1">
                <img src="../../assets/images/dashboard/img_1.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_4.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
              <div class="col-6 pl-1">
                <img src="../../assets/images/dashboard/img_2.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_3.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
            </div>
            <div class="d-flex mt-5 align-items-top">
              <img src="../../assets/images/faces/face3.jpg" class="img-sm rounded-circle mr-3" alt="image">
              <div class="mb-0 flex-grow">
                <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
                <p class="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the readable content of a page.</p>
              </div>
              <div class="ml-auto">
                <i class="mdi mdi-heart-outline text-muted"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--
    <div class="row">
      <div class="col-md-7 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Project Status</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Name </th>
                    <th> Due Date </th>
                    <th> Progress </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 1 </td>
                    <td> Herman Beck </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 2 </td>
                    <td> Messsy Adam </td>
                    <td> Jul 01, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 3 </td>
                    <td> John Richards </td>
                    <td> Apr 12, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-warning" role="progressbar" style="width: 90%" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 4 </td>
                    <td> Peter Meggik </td>
                    <td> May 15, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-primary" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Edward </td>
                    <td> May 03, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-danger" role="progressbar" style="width: 35%" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td> 5 </td>
                    <td> Ronald </td>
                    <td> Jun 05, 2015 </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-info" role="progressbar" style="width: 65%" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-white">Todo</h4>
            <todo-list />
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
//import visitAndSalesStatitics from '../../components/charts/widgets/visitAndSalesStatitics'
//import trafficSourceChart from '../../components/charts/widgets/trafficSourceChart'
//import todoList from '../../components/apps/todoList'
//import DatePicker from 'vue2-datepicker';


export default {
  name: 'dashboard',
  components: {
    /*DatePicker,
    trafficSourceChart,
    visitAndSalesStatitics,
    todoList */
  },
  data() {
    return {
      time1: null
    }
  },
  computed: {
    stats() {
      return this.$store.state.admin.stats
    },
    browsers() {
      if (this.$store.state.admin.browsers.browsers) return this.$store.state.admin.browsers.browsers[0].browser
      return []
    },
    browserUrl() {
      if (this.$store.state.admin.browsers.url) return this.$store.state.admin.browsers.url
      else return ''
    },
    session() {
      return this.$store.state.admin.session
    },
    pageViews() {
      return this.$store.state.admin.pageViews
    },
    analyticsUsers() {
      return this.$store.state.admin.analyticsUsers
    }
  },
  created() {
    this.$store.dispatch("admin/fetchAnalyticsTopBrowsers")
    this.$store.dispatch("admin/fetchStats")
    this.$store.dispatch("admin/fetchAnalyticsTopPageViews")
    this.$store.dispatch("admin/fetchAnalyticsTopSession")
    this.$store.dispatch("admin/fetchAnalyticsUsers")
  }
}
</script>

<style scoped>

</style>
